import styled from 'styled-components';

export const borderFormColor = '#cccccc';

/* Main styled components */

export const ViewContainer = styled.div`
  width: 100%;
  height: 100%;

  .cms #menu-bar,
  .custom-background-color {
    background-color: ${(props) => `${props.colorSettings.backgroundColor}`} !important;
  }

  #menu-left {
    background-color: ${(props) => `${props.colorSettings.leftMenuColor}`} !important;
  }

  #menu-left .nav > li > a {
    color: ${(props) => `${props.colorSettings.leftMenuTextColor}`} !important;
  }

  #menu-left .nav > li > a:hover,
  #menu-left .nav > li > a:focus {
    background-color: ${(props) => `${props.colorSettings.leftMenuHoverColor}`} !important;
  }

  #menu-left .nav > li.active::before {
    background-color: ${(props) => `${props.colorSettings.leftMenuTextColor}`};
  }

  #menu-right > nav {
    background-color: ${(props) => `${props.colorSettings.rightMenuColor}`};
    border-color: ${(props) => `${props.colorSettings.rightMenuColor}`};
  }

  #menu-right .nav > li > a {
    color: ${(props) => `${props.colorSettings.rightMenuTextColor}`} !important;
  }

  #menu-right .nav > li > span {
    color: ${(props) => `${props.colorSettings.rightMenuHeadersColor}`} !important;
  }

  #menu-right .nav > li > a:hover,
  #menu-right .nav > li > a:focus {
    background-color: ${(props) => `${props.colorSettings.rightMenuHoverColor}`} !important;
  }

  #menu-right .nav > li.active::before {
    background-color: ${(props) => `${props.colorSettings.rightMenuTextColor}`};
  }
`;

export const Container = styled.div`
  height: 100vh !important;
  margin: 0px !important;
  padding: 0px !important;
`;

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 200px);
  height: 100%;
  background-color: #f1f1f2;
`;

export const MainHeader = styled.div`
  width: calc(100% - 40px);
  height: 70px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MainFooter = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 18pt;
  font-weight: bold;
`;

export const SideMenuWrapper = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #1754bc;
  color: #6e9fdc;
  padding-top: 10px;

  & > div {
    width: calc(100% - 30px);
    height: 50px;
    line-height: 50px;
    font-size: 11pt;
    font-weight: bold;
    padding-left: 30px;
    cursor: pointer;

    :hover {
      background-color: #154dad;
    }
  }
`;

export const AuthWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url('/static/img/background/login.png');
  background-color: #2564d5;
  background-repeat: no-repeat;
  background-position: 20px 100%;
`;

/* Panel */

export const PanelWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  & > form {
    width: 90%;
  }
`;

/* Basic View for most pages */

export const CenterView = styled.div`
  width: 100%;
  height: ${(props) => props.height || '400px'};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

/* Image Containers */

export const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  margin-top: ${(props) => props.marginTop || '0px'};
  margin-left: ${(props) => props.marginLeft || '0px'};

  & > img {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
    max-width: ${(props) => props.size};
    max-height: ${(props) => props.size};
  }
`;

/* Lists - elements in columns */

export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'initial'};
  margin-top: ${(props) => props.marginTop || '0px'};
  margin-left: ${(props) => props.marginLeft || '0px'};

  & > div {
    margin-bottom: ${(props) => props.interval || '10px'};
  }
`;

export const ProfileIconList = styled(List)`
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 15px;

  & > div {
    color: #a6a8b1;

    :last-child {
      font-weight: bold;
      color: #185ec4;
    }
  }
`;

/* Boxes - elements in rows. Flex-start box */

export const StartBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${(props) => props.marginTop || '0px'};
`;

export const ItemBox = styled(StartBox)`
  width: 400px;
  height: 32px;
  & > label {
    width: 100px !important;
  }
  & > div {
    width: 300px;
  }
  & > select {
    outline: 0;
  }
  & > textarea {
    width: 300px;
    outline: 0;
  }
`;

/* Space-between box */

export const BetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'initial'};
  margin-top: ${(props) => props.marginTop || '0px'};
  margin-left: ${(props) => props.marginLeft || '0px'};
`;

export const HorizontalView = styled(BetweenBox)`
  position: relative;
  border-bottom: ${(props) => (!props.noborder ? '0.8px solid rgb(222, 222, 222)' : '')};
  margin-left: ${(props) => (!props.nomargin ? '2em' : '0em')};
  padding-bottom: 1em;
  height: ${(props) => props.height || 'inherit'};
  max-width: ${(props) => props.maxWidth || '100%'};
`;

/* Box with center align-items */

export const CenterAlignBox = styled(BetweenBox)`
  align-items: center;
`;

export const ButtonsBox = styled(CenterAlignBox)`
  height: ${(props) => props.height || '50px'};
  position: ${(props) => (props.inherit ? 'inherit' : 'absolute')};
  right: 0;
  top: ${(props) => props.top || '4em'};
`;

/* Wrappers: elements to decorate other elements */

export const ListWrapper = styled.div`
  position: relative;
  background-color: #ffffff !important;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: ${(props) => (props.ml ? props.ml : '')};
`;

export const LoginFormWrapper = styled.div`
  width: 400px;
  height: 250px;
  background-color: #f4f7fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    :first-child {
      font-weight: 100;
      font-size: 24px;
    }
  }
`;

export const MenuUserFlo = styled.div`
  & > div > a {
    ::after {
      width: 30px;
      content: '';
      position: absolute;
      height: 30px;
      top: 20px;
      background: no-repeat};
    }
  }
`;
