import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { permissions } from '../../constants/permissions';
import { Router, Link } from '../../../common/routes';
import { Tooltip } from 'react-tooltip';

const SideMenuCafmView = ({
  pathname,
  currentSystem,
  t,
  accountUUID,
  activeSiteId,
  userPermissions,
  router,
  jobItem,
  activeSite,
  currentuser,
}) => {
  const sitePermissions =
    userPermissions &&
    userPermissions.sitePermissions
      .filter((sp) => sp.siteId === activeSiteId)
      .map(({ permissions }) => permissions)
      .toString();
  const allSitePermissions = userPermissions ? userPermissions.permissions.map((permission) => permission) : [];

  const reportsPaths = [
    '/cafm/user/reports/reports_list_default',
    '/cafm/user/reports/reports_list_multiple',
    '/cafm/user/reports/timelog',
  ];

  const taskManagerPaths = [
    '/cafm/user/reports/task_dashboard',
    '/cafm/user/reports/task_calendar',
    '/cafm/user/reports/task_activity_calendar',
  ];

  const helpdeskPaths = ['/cafm/helpdesk/helpdesk_view'];

  function sideBarMenuToggle(e) {
    const menuDiv = window.document.getElementById('side menu menuDiv');
    Object.values(menuDiv.children).forEach((ele) => {
      ele.classList.remove('active');
      if (ele.children.length > 1) {
        ele.children[1].classList.remove('in');
      }
    });
    e.target.parentNode.classList.toggle('active');
    e.target.parentNode.children[1].classList.toggle('in');
  }

  function checkPermission(param, isDefault = true) {
    return isDefault
      ? allSitePermissions.includes(param + '.view.all') ||
          sitePermissions.includes(param + '.view.own') ||
          sitePermissions.includes(param + '.view.all')
      : allSitePermissions.includes(param) || sitePermissions.includes(param);
  }

  return (
    <>
      <ul className="nav metismenu" id="side menu menuDiv">
        <li className={pathname === '/cafm/' ? 'active' : ''}>
          <a onClick={() => (window.location = '/cafm/site/select/reset')}>{t(activeSiteId ? '<- Sites' : 'Sites')}</a>
        </li>
        {activeSiteId && (
          <li className={pathname === '/cafm/' ? 'active' : ''}>
            <a onClick={() => (window.location = '/cafm/')}>{t('DASHBOARD')}</a>
          </li>
        )}
        {checkPermission('cafm.assets.register') && activeSiteId && (
          <li className={pathname === '/cafm/' ? 'active' : ''}>
            <a onClick={() => (window.location = '/cafm/assets')}>{t('ASSETS')}</a>
          </li>
        )}
        {checkPermission('cafm.companies') && (
          <li className={pathname === '/cafm/' ? 'active' : ''}>
            <a onClick={() => (window.location = '/cafm/company/active')}>{t('COMPANIES')}</a>
          </li>
        )}
        {checkPermission('cafm.documents') && (
          <li className={pathname === '/cafm/' ? 'active' : ''}>
            <a onClick={() => (window.location = '/cafm/documents')}>{t('DOCUMENTS')}</a>
          </li>
        )}
        {checkPermission('cafm.helpdesk') && (
          <li className={helpdeskPaths.includes(pathname) ? 'active' : ''}>
            <a onClick={() => (window.location = '/cafm/helpdesk')}>{t('HELPDESK')}</a>
          </li>
        )}
        {checkPermission('cafm.calendar') && (
          <li
            className={
              ['/cafm/user/reports/planned_report', '/cafm/user/reports/planned_report_all'].includes(pathname) ||
              jobItem?.jobType === 1
                ? 'active'
                : ''
            }
          >
            <a onClick={(e) => (window.location = '/cafm/ppm/wallchart')}>{t('PLANNED TASKS')}</a>
            <ul
              className={
                ['/cafm/user/reports/planned_report', '/cafm/user/reports/planned_report_all'].includes(pathname) ||
                jobItem?.jobType === 1
                  ? 'collapse in'
                  : 'collapse'
              }
            >
              <li>
                <a onClick={() => (window.location = '/cafm/ppm/wallchart')}>{t('calendar')}</a>
              </li>
              <li>
                <a
                  onClick={() =>
                    (window.location = '/cafm/user/report/planned' + (activeSiteId ? '/' + activeSiteId : ''))
                  }
                >
                  {t('list')}
                </a>
              </li>
            </ul>
          </li>
        )}
        {checkPermission('cafm.postroom') && (
          <li className={pathname.includes('cafm/postroom') ? 'active' : ''}>
            <a onClick={(e) => sideBarMenuToggle(e)}>{t('POSTROOM')}</a>
            <ul className={pathname.includes('cafm/postroom') ? 'collapse in' : 'collapse'}>
              <li>
                <a className="nav-link" onClick={() => (window.location = '/cafm/postroom?type=delivery')}>
                  {t('Delivery')}
                </a>
              </li>
              <li>
                <a className="nav-link" onClick={() => (window.location = '/cafm/postroom?type=collection')}>
                  {t('Collections')}
                </a>
              </li>
              <li>
                <a className="nav-link" onClick={() => (window.location = '/cafm/postroom/booking/calendar')}>
                  {t('Bay Bookings')}
                </a>
              </li>
            </ul>
          </li>
        )}
        {checkPermission('cafm.reactive') && (
          <li
            className={
              ['/cafm/user/reports/reactive_report', '/cafm/user/reports/reactive_report_all'].includes(pathname) ||
              jobItem?.jobType === 2
                ? 'active'
                : ''
            }
          >
            <a onClick={(e) => (window.location = '/cafm/reactive/wallchart')}>{t('REACTIVE TASKS')}</a>
            <ul
              className={
                ['/cafm/user/reports/reactive_report', '/cafm/user/reports/reactive_report_all'].includes(pathname) ||
                jobItem?.jobType === 2
                  ? 'collapse in'
                  : 'collapse'
              }
            >
              <li>
                <a onClick={() => (window.location = '/cafm/reactive/wallchart')}>{t('calendar')}</a>
              </li>
              <li>
                <a
                  className="nav-link"
                  onClick={() =>
                    (window.location = '/cafm/user/report/reactive' + (activeSiteId ? '/' + activeSiteId : ''))
                  }
                >
                  {t('list')}
                </a>
              </li>
            </ul>
          </li>
        )}
        {(checkPermission(permissions.site.reportGlobal, false) ||
          checkPermission(permissions.site.reportEngineer, false) ||
          checkPermission(permissions.site.reportTimeSpent, false)) && (
          <li className={reportsPaths.includes(pathname) ? 'active' : ''}>
            <a onClick={(e) => sideBarMenuToggle(e)}>{t('REPORTING')}</a>
            <ul className={reportsPaths.includes(pathname) ? 'collapse in' : 'collapse'}>
              {checkPermission(permissions.site.reportEngineer, false) && (
                <li>
                  <a className="nav-link" onClick={() => (window.location = '/cafm/user/reports/details/multiple')}>
                    {t('Engineer')}
                  </a>
                </li>
              )}
              {checkPermission(permissions.site.reportGlobal, false) && (
                <li>
                  <a className="nav-link" onClick={() => (window.location = '/cafm/user/reports/details/default')}>
                    {t('Global')}
                  </a>
                </li>
              )}
              {checkPermission(permissions.site.reportTimeSpent, false) && (
                <li>
                  <a className="nav-link" onClick={() => (window.location = '/cafm/user/reports/timelog')}>
                    {t('Time Spent')}
                  </a>
                </li>
              )}
              {checkPermission(permissions.site.reportGlobal, false) && (
                <li>
                  <a
                    className="nav-link"
                    onClick={() => (window.location = '/cafm/user/reports/task-activity-calendar')}
                  >
                    {t('Engineer Activity')}
                  </a>
                </li>
              )}
              {checkPermission(permissions.site.reportGlobal, false) && (
                <li>
                  <a className="nav-link" onClick={() => (window.location = '/cafm/user/reports/task-calendar')}>
                    {t('Engineer Calendar')}
                  </a>
                </li>
              )}
            </ul>
          </li>
        )}
        {checkPermission('cafm.induction') && !activeSiteId && (
          <li className={pathname === '/cafm/' ? 'active' : ''}>
            <a onClick={() => (window.location = '/cafm/induction')}>{t('Induction')}</a>
          </li>
        )}
        {checkPermission('cafm.permits') && (
          <li className={pathname === '/cafm/' ? 'active' : ''}>
            <a onClick={() => (window.location = '/cafm/permit/open')}>{t('permits')}</a>
          </li>
        )}
        {checkPermission('cafm.risks') && (
          <li className={pathname === '/cafm/' ? 'active' : ''}>
            <a onClick={() => (window.location = '/cafm/risk/list/all')}>{t('RISKS')}</a>
          </li>
        )}
        {(allSitePermissions.includes('cafm.site.admin.all') ||
          sitePermissions.includes('cafm.site.admin.own') ||
          sitePermissions.includes('cafm.site.admin.all')) &&
          activeSiteId && (
            <li className={pathname === '/cafm/' ? 'active' : ''}>
              <a onClick={() => (window.location = '/cafm/admin/site/' + activeSiteId + '/profile')}>
                {t('SITE ADMIN ')}
              </a>
            </li>
          )}

        {/* <li className={pathname === '/cafm/user/email_template/templates' ? 'active' : ''}>
          <a href="/cafm/user/email-templates">{t('Email Templates')}</a>
        </li> */}
        <li className={taskManagerPaths.includes(pathname) ? 'active' : ''}>
          <a onClick={(e) => sideBarMenuToggle(e)}>
            {t('TASK MANAGER')}
            <span className="beta-icon" data-tooltip-id="my-tooltip" data-tooltip-place="top-start">
              BETA
            </span>
          </a>

          <ul className={taskManagerPaths.includes(pathname) ? 'collapse in' : 'collapse'}>
            <li>
              <a className="nav-link" onClick={() => (window.location = '/cafm/user/reports/task-dashboard')}>
                {t('dashboard')}
              </a>
            </li>

            <li>
              <a className="nav-link" onClick={() => (window.location = '/cafm/user/reports/task-calendar')}>
                {t('calendar')}
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <Tooltip
        anchorSelect=".beta-icon"
        style={tooltipStyle}
        opacity={1}
        border="1px solid black"
        place="bottom"
        render={() => (
          <div style={{ fontSize: 13, maxWidth: '300px', display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 'bold', alignSelf: 'center' }}>Work In Progress!</span>
            <span>
              You're using a feature in beta mode! This feature is still being tested and improved. Expect updates and
              feel free to share any feedback to help us make it better.
            </span>
          </div>
        )}
      />
    </>
  );
};

SideMenuCafmView.propTypes = {
  pathname: PropTypes.string.isRequired,
  currentSystem: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  userPermissions: PropTypes.shape(),
};

const tooltipStyle = {
  padding: 10,
  minHeight: 100,
  fontSize: 13,
  background: '#EBF5FF',
  color: '#000000',
  borderRadius: 10,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
};

export default SideMenuCafmView;
